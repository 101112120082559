#home .portada {
    width: 100%;
    height: 50vh;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
}

#home .portada img {
    width: 100%;
    height: auto;
    /* transform: translateY(-200px); */
}

#home .portadaTexto {
    background: linear-gradient(0deg, #171717eb 10%, #17171700 50%);
    height: calc(100% - 60px);
    width: calc(100% - 60px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px;
    color: white;
}

#home .button1 {
    margin-top: 20px;
}

#home .presentacion {
    margin: 20px 0px;
}

#home .Especial {
    height: 30vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#home .Especial button{
    margin: 30px 0;
}