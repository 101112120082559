#FormW {
    min-height: calc(100vh - 68px);
    background-image: url(./woman.webp);
    background-size: cover;
    background-position: center;
    position: relative;
}

#FormW .filter {
    position: static;
    top: 0;
    left: 0;
    min-height: calc(100vh - 68px);
    width: 100%;
    background-color: rgba(12, 65, 67, 0.291);
    display: flex;
    justify-content: center;
    align-items: center;
}

#FormW .boxw {
    background-color: rgba(255, 255, 255, 0.655);
    padding: 50px;
    max-width: 350px;
    backdrop-filter: blur(10px);
    border-radius:15px;
    box-shadow: 0 0 15px 0 rgba(60, 60, 60, 0.533);
    color: #4388a9;
}

#FormW .input {
    width: 100%;
    margin: 15px 0;
}