div#NotFound {
    height: calc(100vh - 108px);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px !important;
}