#welcome {
    min-height: calc(100vh - 68px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#welcome h1 {
    color: #4388a9;
}

#welcome button {
    margin: 30px 0;
}